import type { User } from "~/types/user";
import { userApi } from "~/utils/api/user";
import { authApi } from "~/utils/api/auth";

const namespace = "userStore";

export const useUserStore = defineStore(namespace, () => {
  const token = ref<string | null>(null);

  const setToken = (value: string) => {
    token.value = value;
  };
  const removeToken = () => {
    token.value = null;
  };

  const user = ref<User | null>(null);
  const setUser = (value: User) => {
    user.value = value;
  };
  const removeUser = () => {
    user.value = null;
  };

  async function loadUser() {
    const { data, error } = await userApi.fetchUser();
    if (error.value) {
      logout();
      return;
    }
    if (data.value) {
      setUser(data.value);
    }
  }

  function logout() {
    removeToken();
    removeUser();
    useCookie("refreshToken", {
      maxAge: -1,
    }).value = "";
  }

  async function revalidateToken() {
    const { data, error } = await authApi.refreshToken();
    if (error.value) {
      logout();
      return;
    }
    if (data.value) {
      setToken(data.value);
    }
  }

  const isLogged = computed(() => !!token.value);
  const isActivated = computed(() => user.value?.activated);

  return {
    token,
    setToken,
    removeToken,
    user,
    setUser,
    removeUser,
    loadUser,
    logout,
    revalidateToken,
    isLogged,
    isActivated,
  };
});
