import type { H3Event } from "h3";
import { apiFetch } from "~/utils/fetch/fetchApi";
import { fetchWithCookie } from "~/utils/fetch/fetchWithCookie";

interface AuthData {
  email: string;
  password: string;
}

interface RegisterData {
  email: string;
  password: string;
  passwordAgain: string;
  type: string;
}

export const authApi = {
  signIn,
  register,
  logout,
  refreshToken,
};

function signIn(data: AuthData) {
  const { $i18n } = useNuxtApp();
  const { locale } = $i18n;
  return $fetch("/front-api/auth/login", {
    method: "POST",
    body: data,
    query: { locale: locale.value },
  });
}

function logout(event: H3Event, headers: HeadersInit = {}) {
  return fetchWithCookie(event, "/front-api/auth/logout", {
    method: "POST",
    headers,
  });
}

function register(data: RegisterData) {
  return apiFetch("/api/v1/users/signup/", {
    method: "POST",
    body: data,
  });
}

function refreshToken() {
  return useFetch<string>("/front-api/auth/refresh");
}
