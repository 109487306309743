import { apiFetch, useApiFetch } from "~/utils/fetch/fetchApi";
import type { ReferralBalance, User } from "~/types/user";
import type { Claims } from "~/types/claims";

const userApiUrl = {
  fetchUserUrl: "/api/v1/users/me/",
  fetchClaimsUrl: `/api/v1/users/me/claims/`,
  activateAccountUrl: (code: string) => `/api/v1/users/activate/${code}/`,
  reactivateAccountUrl: "/api/v1/users/reactivate/",
  fetchReferralBalanceUrl: "/api/v1/users/me/refferal/",
  fetchReferralWithdrawsUrl: "/api/v1/users/me/refferal/withdrawal/",
  fetchDwUrl: (page: number, size: number) =>
    `/api/v1/users/me/refferal/dw/?page=${page}&size=${size}`,
  changePasswordUrl: "/api/v1/users/me/password/",
  restoreAccountUrl: "/api/v1/users/restore/",
  resetAccountUrl: (code: string) => `/api/v1/users/restore/${code}/`,
  fetchPromoCodesUrl: "/api/v1/users/me/promocodes/",
};

export const userApi = {
  fetchUser,
  fetchClaims,
  activateAccount,
  reactivateAccount,
  fetchReferralBalance,
  fetchReferralWithdraws,
  fetchReferralWithdraw,
  fetchDw,
  changePassword,
  restoreAccount,
  resetAccount,
  fetchPromoCodes,
};

function fetchUser() {
  return useApiFetch<User>(userApiUrl.fetchUserUrl);
}

function fetchClaims({
  page,
  size,
  status,
}: {
  page: number;
  size: number;
  status?: string;
}) {
  return apiFetch<Claims>(userApiUrl.fetchClaimsUrl, {
    query: { page, size, status },
  });
}

function activateAccount(code: string) {
  return apiFetch(userApiUrl.activateAccountUrl(code), { method: "POST" });
}

function reactivateAccount() {
  return useApiFetch(userApiUrl.reactivateAccountUrl, {
    method: "POST",
  });
}

function fetchReferralBalance() {
  return useApiFetch<ReferralBalance>(userApiUrl.fetchReferralBalanceUrl);
}

function fetchReferralWithdraws() {
  return useApiFetch(userApiUrl.fetchReferralWithdrawsUrl);
}

function fetchReferralWithdraw(data: any) {
  return apiFetch(userApiUrl.fetchReferralWithdrawsUrl, {
    method: "POST",
    body: data,
  });
}

function fetchDw(page: number, size: number) {
  return useApiFetch(userApiUrl.fetchDwUrl(page, size));
}

function changePassword(data: any) {
  return apiFetch(userApiUrl.changePasswordUrl, {
    method: "POST",
    body: data,
  });
}

function restoreAccount(data: any) {
  return apiFetch(userApiUrl.restoreAccountUrl, { method: "POST", body: data });
}

function resetAccount(code: string, data: any) {
  return apiFetch(userApiUrl.resetAccountUrl(code), {
    method: "POST",
    body: data,
  });
}

function fetchPromoCodes() {
  return useApiFetch(userApiUrl.fetchPromoCodesUrl, {
    server: false,
    lazy: true,
  });
}
