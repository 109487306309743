import type { LocationQuery } from "vue-router";
import { apiFetch, useApiFetch } from "~/utils/fetch/fetchApi";
import type {
  Course,
  Direction,
  Filter,
  PairData,
  Reserve,
} from "~/types/calculator";
import useFetchWithCache from "~/composables/useFetchWithCache";
import { useUserStore } from "~/stores/useUserStore";

const calculatorApiUrl = {
  getDirectionsUrl: "/api/v1/calculator/",
  getCourseUrl: (from: number, to: number) =>
    `/api/v1/calculator/course/${from}/${to}/`,
  getFromCurrenciesUrl: "/api/v1/calculator/from/",
  getToCurrenciesUrl: (from: number) => `/api/v1/calculator/from/${from}/`,
  getPairDataUrl: (from: number, to: number) =>
    `/api/v1/calculator/pair/${from}/${to}/`,
  getReservesUrl: "/api/v1/calculator/reserves/",
  getSettingsUrl: "/api/v1/calculator/settings/",
  getTechUrl: "/api/v1/calculator/tech/",
  getInitDataUrl: "/front-api/calculator/init/",
};

export const calculatorApi = {
  getDirections,
  getCourse,
  getFromCurrencies,
  getToCurrencies,
  getPairData,
  getReserves,
  fetchInitData,
  fetchTech,
  fetchSettings,
};

function getDirections() {
  return useFetchWithCache<Direction[]>(calculatorApiUrl.getDirectionsUrl);
}

function getCourse({ from, to }: { from: number; to: number }) {
  return apiFetch<Course>(calculatorApiUrl.getCourseUrl(from, to));
}

function getFromCurrencies() {
  return apiFetch<number[]>(calculatorApiUrl.getFromCurrenciesUrl);
}

function getToCurrencies({ from }: { from: number }) {
  return apiFetch<number[]>(calculatorApiUrl.getToCurrenciesUrl(from));
}

function getPairData({ from, to }: { from: number; to: number }) {
  return apiFetch<PairData>(calculatorApiUrl.getPairDataUrl(from, to));
}

function getReserves() {
  return useApiFetch<Reserve[]>(calculatorApiUrl.getReservesUrl, {
    server: false,
    lazy: true,
  });
}

function fetchInitData(query: LocationQuery) {
  const { token } = useUserStore();

  return useFetch("/front-api/calculator/init", {
    query,
    headers: {
      Authorization: token ? `Bearer ${token}` : "",
    },
  });
}

interface fetchTechResponse {
  technicalMode: {
    time: number;
    on: boolean;
  };
}

function fetchTech() {
  return apiFetch<fetchTechResponse>(calculatorApiUrl.getTechUrl);
}

interface fetchSettingsResponse {
  filters: {
    main: Filter[];
    alternative: Filter[];
  };
  contest: boolean;
}

function fetchSettings() {
  return useApiFetch<fetchSettingsResponse>(calculatorApiUrl.getSettingsUrl, {
    lazy: true,
    pick: ["filters", "contest"],
  });
}
