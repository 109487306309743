import { defu } from "defu";
import type { UseFetchOptions } from "#app";
import type { NitroFetchOptions } from "nitropack";
import { useUserStore } from "~/stores/useUserStore";
import { getBaseUrl } from "~/utils/baseUrl";

function getApiFetchDefaultOption<T>(): UseFetchOptions<T> {
  return {
    baseURL: getBaseUrl(),

    onRequest({ options }): Promise<void> | void {
      const { token } = useUserStore();
      if (token) {
        options.headers = {
          ...options.headers,
          Authorization: `Bearer ${token}`,
        };
      }
    },

    async onResponse(context) {
      const { response } = context;
      if (
        response.status === 401 &&
        response._data?.code === "token_not_valid"
      ) {
        if (response.url.includes("/auth/refresh")) {
          await useUserStore().logout();
        } else {
          try {
            await useUserStore().revalidateToken();
            if (!useUserStore().token) {
              throw new Error("Token not valid");
            }
            context.options.headers = {
              ...context.options.headers,
              Authorization: `Bearer ${useUserStore().token}`,
            };
            context.response = await $fetch.raw(
              context.request,
              context.options as NitroFetchOptions<any>,
            );
          } catch (e: any) {
            await useUserStore().logout();
          }
        }
      }
    },
  };
}

export function useApiFetch<T>(url: string, options: UseFetchOptions<T> = {}) {
  const defaultOptions = getApiFetchDefaultOption<T>();
  const params = defu(options, defaultOptions);
  return useFetch(url, params);
}

export function apiFetch<T>(url: string, options: NitroFetchOptions<any> = {}) {
  const defaultOptions = getApiFetchDefaultOption<T>();
  const params = defu(options, defaultOptions);

  return $fetch(url, params) as Promise<T>;
}
