import type { PairData } from "~/types/calculator";
import { calculatorApi } from "~/utils/api/calculator";
import useDirectionsCalculator from "~/composables/calculator/useDirectionsCalculator";

const namespace = "calculator";

export const useCalculatorStore = defineStore(namespace, () => {
  const fromCurrencyId = ref<number | null>(null);
  const setFromCurrencyId = (value: number) => {
    fromCurrencyId.value = value;
  };

  const toCurrencyId = ref<number | null>(null);
  const setToCurrencyId = (value: number) => {
    toCurrencyId.value = value;
  };

  const fromCurrencies = ref<number[] | null>(null);
  const setFromCurrencies = (value: number[]) => {
    fromCurrencies.value = value;
  };

  const toCurrencies = ref<number[] | null>(null);
  const setToCurrencies = (value: number[]) => {
    toCurrencies.value = value;
  };

  const refferal = ref<string | null>(null);
  const setRefferal = (value: string) => {
    refferal.value = value;
  };

  const promocode = ref<string | null>(null);
  const setPromocode = (value: string) => {
    promocode.value = value;
  };

  const course = ref<number | null>(null);
  const setCourse = (value: number) => {
    course.value = value;
  };

  const pairData = ref<PairData | null>(null);
  const setPairData = (value: PairData) => {
    pairData.value = value;
  };

  const recaptcha = ref("");

  const initCalculator = async () => {
    const { query } = useRoute();
    const { $i18n } = useNuxtApp();
    const locale = $i18n.locale.value;
    const { data, error } = await calculatorApi.fetchInitData({
      locale,
      ...query,
    });
    if (error.value) {
      throw createError({
        statusCode: 500,
        statusMessage: `initCalculator: Could not fetch data`,
        fatal: true,
      });
    }
    if (data.value) {
      setFromCurrencies(data.value.fromIds);
      setToCurrencies(data.value.toIds);
      if (data.value.fromCurrencyId && data.value.toCurrencyId) {
        setFromCurrencyId(data.value.fromCurrencyId);
        setToCurrencyId(data.value.toCurrencyId);
      }
      setPairData(data.value.pairData);
      updateData();
    }
  };
  const fromValue = ref<number | null>(null);
  const setFromValue = (value: number) => {
    fromValue.value = value;
  };

  const toValue = ref<number | null>(null);
  const updateData = () => {
    if (!pairData.value) {
      return;
    }
    setCourse(pairData.value.course);
    if (!course.value) {
      return;
    }
    const minValue = pairData.value.from.min
      ? pairData.value.from.min
      : pairData.value.to.min / course.value;
    setFromValue(minValue);
    toValue.value = roundNumber(
      minValue * course.value,
      pairData.value.to.roundCalculator,
    );
  };

  watch(
    () => useUserStore().isLogged,
    async () => {
      await useDirectionsCalculator().onCurrencyChange();
    },
  );

  const changedValue = ref<"from" | "to">("from");

  const setChangedValue = (value: "from" | "to") => {
    changedValue.value = value;
  };

  return {
    fromCurrencyId,
    setFromCurrencyId,
    toCurrencyId,
    setToCurrencyId,
    fromCurrencies,
    setFromCurrencies,
    toCurrencies,
    setToCurrencies,
    refferal,
    setRefferal,
    course,
    setCourse,
    pairData,
    setPairData,
    initCalculator,
    fromValue,
    setFromValue,
    updateData,
    toValue,
    promocode,
    setPromocode,
    changedValue,
    setChangedValue,
    recaptcha,
  };
});
