import { default as index3K08KgdD4HMeta } from "/workdir/pages/account/orders/index.vue?macro=true";
import { default as indexnnmIjve6FNMeta } from "/workdir/pages/account/promocode/index.vue?macro=true";
import { default as indexFTK336zVsqMeta } from "/workdir/pages/account/referrer/index.vue?macro=true";
import { default as indexpcvyTXpDSqMeta } from "/workdir/pages/account/settings/index.vue?macro=true";
import { default as accountQy3Qfs0j0GMeta } from "/workdir/pages/account.vue?macro=true";
import { default as indexqv8wFxnoAiMeta } from "/workdir/pages/aml-kyc/index.vue?macro=true";
import { default as _91uuid_937dDFkVhV3mMeta } from "/workdir/pages/claim/[uuid].vue?macro=true";
import { default as failXdaHQUDRb0Meta } from "/workdir/pages/claim/fail.vue?macro=true";
import { default as indexIPJFi2Je1FMeta } from "/workdir/pages/claim/index.vue?macro=true";
import { default as successLtUxxRtTjZMeta } from "/workdir/pages/claim/success.vue?macro=true";
import { default as indexySkdaZZjB0Meta } from "/workdir/pages/contacts/index.vue?macro=true";
import { default as indexMKwREhkAPMMeta } from "/workdir/pages/contest/index.vue?macro=true";
import { default as indexNrjrPDWjinMeta } from "/workdir/pages/discounts/index.vue?macro=true";
import { default as indexYqXC3W8KKRMeta } from "/workdir/pages/faq/index.vue?macro=true";
import { default as indexJ1sPgYGdnvMeta } from "/workdir/pages/index.vue?macro=true";
import { default as indexttwSwVVJLoMeta } from "/workdir/pages/partners/index.vue?macro=true";
import { default as indexk3qcO1cw9bMeta } from "/workdir/pages/rules/index.vue?macro=true";
import { default as _91uuid_93FBaU04Y9qoMeta } from "/workdir/pages/verify/[uuid].vue?macro=true";
import { default as component_45stubWRgRVU5zlHMeta } from "/workdir/node_modules/nuxt/dist/pages/runtime/component-stub?macro=true";
import { default as component_45stubWRgRVU5zlH } from "/workdir/node_modules/nuxt/dist/pages/runtime/component-stub";
export default [
  {
    name: accountQy3Qfs0j0GMeta?.name ?? "account___ru",
    path: accountQy3Qfs0j0GMeta?.path ?? "/account",
    meta: accountQy3Qfs0j0GMeta || {},
    alias: accountQy3Qfs0j0GMeta?.alias || [],
    redirect: accountQy3Qfs0j0GMeta?.redirect,
    component: () => import("/workdir/pages/account.vue").then(m => m.default || m),
    children: [
  {
    name: index3K08KgdD4HMeta?.name ?? "account-orders___ru",
    path: index3K08KgdD4HMeta?.path ?? "orders",
    meta: index3K08KgdD4HMeta || {},
    alias: index3K08KgdD4HMeta?.alias || [],
    redirect: index3K08KgdD4HMeta?.redirect,
    component: () => import("/workdir/pages/account/orders/index.vue").then(m => m.default || m)
  },
  {
    name: indexnnmIjve6FNMeta?.name ?? "account-promocode___ru",
    path: indexnnmIjve6FNMeta?.path ?? "promocode",
    meta: indexnnmIjve6FNMeta || {},
    alias: indexnnmIjve6FNMeta?.alias || [],
    redirect: indexnnmIjve6FNMeta?.redirect,
    component: () => import("/workdir/pages/account/promocode/index.vue").then(m => m.default || m)
  },
  {
    name: indexFTK336zVsqMeta?.name ?? "account-referrer___ru",
    path: indexFTK336zVsqMeta?.path ?? "referrer",
    meta: indexFTK336zVsqMeta || {},
    alias: indexFTK336zVsqMeta?.alias || [],
    redirect: indexFTK336zVsqMeta?.redirect,
    component: () => import("/workdir/pages/account/referrer/index.vue").then(m => m.default || m)
  },
  {
    name: indexpcvyTXpDSqMeta?.name ?? "account-settings___ru",
    path: indexpcvyTXpDSqMeta?.path ?? "settings",
    meta: indexpcvyTXpDSqMeta || {},
    alias: indexpcvyTXpDSqMeta?.alias || [],
    redirect: indexpcvyTXpDSqMeta?.redirect,
    component: () => import("/workdir/pages/account/settings/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: accountQy3Qfs0j0GMeta?.name ?? "account___en",
    path: accountQy3Qfs0j0GMeta?.path ?? "/en/account",
    meta: accountQy3Qfs0j0GMeta || {},
    alias: accountQy3Qfs0j0GMeta?.alias || [],
    redirect: accountQy3Qfs0j0GMeta?.redirect,
    component: () => import("/workdir/pages/account.vue").then(m => m.default || m),
    children: [
  {
    name: index3K08KgdD4HMeta?.name ?? "account-orders___en",
    path: index3K08KgdD4HMeta?.path ?? "orders",
    meta: index3K08KgdD4HMeta || {},
    alias: index3K08KgdD4HMeta?.alias || [],
    redirect: index3K08KgdD4HMeta?.redirect,
    component: () => import("/workdir/pages/account/orders/index.vue").then(m => m.default || m)
  },
  {
    name: indexnnmIjve6FNMeta?.name ?? "account-promocode___en",
    path: indexnnmIjve6FNMeta?.path ?? "promocode",
    meta: indexnnmIjve6FNMeta || {},
    alias: indexnnmIjve6FNMeta?.alias || [],
    redirect: indexnnmIjve6FNMeta?.redirect,
    component: () => import("/workdir/pages/account/promocode/index.vue").then(m => m.default || m)
  },
  {
    name: indexFTK336zVsqMeta?.name ?? "account-referrer___en",
    path: indexFTK336zVsqMeta?.path ?? "referrer",
    meta: indexFTK336zVsqMeta || {},
    alias: indexFTK336zVsqMeta?.alias || [],
    redirect: indexFTK336zVsqMeta?.redirect,
    component: () => import("/workdir/pages/account/referrer/index.vue").then(m => m.default || m)
  },
  {
    name: indexpcvyTXpDSqMeta?.name ?? "account-settings___en",
    path: indexpcvyTXpDSqMeta?.path ?? "settings",
    meta: indexpcvyTXpDSqMeta || {},
    alias: indexpcvyTXpDSqMeta?.alias || [],
    redirect: indexpcvyTXpDSqMeta?.redirect,
    component: () => import("/workdir/pages/account/settings/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexqv8wFxnoAiMeta?.name ?? "aml-kyc___ru",
    path: indexqv8wFxnoAiMeta?.path ?? "/aml-kyc",
    meta: indexqv8wFxnoAiMeta || {},
    alias: indexqv8wFxnoAiMeta?.alias || [],
    redirect: indexqv8wFxnoAiMeta?.redirect,
    component: () => import("/workdir/pages/aml-kyc/index.vue").then(m => m.default || m)
  },
  {
    name: indexqv8wFxnoAiMeta?.name ?? "aml-kyc___en",
    path: indexqv8wFxnoAiMeta?.path ?? "/en/aml-kyc",
    meta: indexqv8wFxnoAiMeta || {},
    alias: indexqv8wFxnoAiMeta?.alias || [],
    redirect: indexqv8wFxnoAiMeta?.redirect,
    component: () => import("/workdir/pages/aml-kyc/index.vue").then(m => m.default || m)
  },
  {
    name: _91uuid_937dDFkVhV3mMeta?.name ?? "claim-uuid___ru",
    path: _91uuid_937dDFkVhV3mMeta?.path ?? "/claim/:uuid()",
    meta: _91uuid_937dDFkVhV3mMeta || {},
    alias: _91uuid_937dDFkVhV3mMeta?.alias || [],
    redirect: _91uuid_937dDFkVhV3mMeta?.redirect,
    component: () => import("/workdir/pages/claim/[uuid].vue").then(m => m.default || m)
  },
  {
    name: _91uuid_937dDFkVhV3mMeta?.name ?? "claim-uuid___en",
    path: _91uuid_937dDFkVhV3mMeta?.path ?? "/en/claim/:uuid()",
    meta: _91uuid_937dDFkVhV3mMeta || {},
    alias: _91uuid_937dDFkVhV3mMeta?.alias || [],
    redirect: _91uuid_937dDFkVhV3mMeta?.redirect,
    component: () => import("/workdir/pages/claim/[uuid].vue").then(m => m.default || m)
  },
  {
    name: failXdaHQUDRb0Meta?.name ?? "claim-fail___ru",
    path: failXdaHQUDRb0Meta?.path ?? "/claim/fail",
    meta: failXdaHQUDRb0Meta || {},
    alias: failXdaHQUDRb0Meta?.alias || [],
    redirect: failXdaHQUDRb0Meta?.redirect,
    component: () => import("/workdir/pages/claim/fail.vue").then(m => m.default || m)
  },
  {
    name: failXdaHQUDRb0Meta?.name ?? "claim-fail___en",
    path: failXdaHQUDRb0Meta?.path ?? "/en/claim/fail",
    meta: failXdaHQUDRb0Meta || {},
    alias: failXdaHQUDRb0Meta?.alias || [],
    redirect: failXdaHQUDRb0Meta?.redirect,
    component: () => import("/workdir/pages/claim/fail.vue").then(m => m.default || m)
  },
  {
    name: indexIPJFi2Je1FMeta?.name ?? "claim___ru",
    path: indexIPJFi2Je1FMeta?.path ?? "/claim",
    meta: indexIPJFi2Je1FMeta || {},
    alias: indexIPJFi2Je1FMeta?.alias || [],
    redirect: indexIPJFi2Je1FMeta?.redirect,
    component: () => import("/workdir/pages/claim/index.vue").then(m => m.default || m)
  },
  {
    name: indexIPJFi2Je1FMeta?.name ?? "claim___en",
    path: indexIPJFi2Je1FMeta?.path ?? "/en/claim",
    meta: indexIPJFi2Je1FMeta || {},
    alias: indexIPJFi2Je1FMeta?.alias || [],
    redirect: indexIPJFi2Je1FMeta?.redirect,
    component: () => import("/workdir/pages/claim/index.vue").then(m => m.default || m)
  },
  {
    name: successLtUxxRtTjZMeta?.name ?? "claim-success___ru",
    path: successLtUxxRtTjZMeta?.path ?? "/claim/success",
    meta: successLtUxxRtTjZMeta || {},
    alias: successLtUxxRtTjZMeta?.alias || [],
    redirect: successLtUxxRtTjZMeta?.redirect,
    component: () => import("/workdir/pages/claim/success.vue").then(m => m.default || m)
  },
  {
    name: successLtUxxRtTjZMeta?.name ?? "claim-success___en",
    path: successLtUxxRtTjZMeta?.path ?? "/en/claim/success",
    meta: successLtUxxRtTjZMeta || {},
    alias: successLtUxxRtTjZMeta?.alias || [],
    redirect: successLtUxxRtTjZMeta?.redirect,
    component: () => import("/workdir/pages/claim/success.vue").then(m => m.default || m)
  },
  {
    name: indexySkdaZZjB0Meta?.name ?? "contacts___ru",
    path: indexySkdaZZjB0Meta?.path ?? "/contacts",
    meta: indexySkdaZZjB0Meta || {},
    alias: indexySkdaZZjB0Meta?.alias || [],
    redirect: indexySkdaZZjB0Meta?.redirect,
    component: () => import("/workdir/pages/contacts/index.vue").then(m => m.default || m)
  },
  {
    name: indexySkdaZZjB0Meta?.name ?? "contacts___en",
    path: indexySkdaZZjB0Meta?.path ?? "/en/contacts",
    meta: indexySkdaZZjB0Meta || {},
    alias: indexySkdaZZjB0Meta?.alias || [],
    redirect: indexySkdaZZjB0Meta?.redirect,
    component: () => import("/workdir/pages/contacts/index.vue").then(m => m.default || m)
  },
  {
    name: indexMKwREhkAPMMeta?.name ?? "contest___ru",
    path: indexMKwREhkAPMMeta?.path ?? "/contest",
    meta: indexMKwREhkAPMMeta || {},
    alias: indexMKwREhkAPMMeta?.alias || [],
    redirect: indexMKwREhkAPMMeta?.redirect,
    component: () => import("/workdir/pages/contest/index.vue").then(m => m.default || m)
  },
  {
    name: indexMKwREhkAPMMeta?.name ?? "contest___en",
    path: indexMKwREhkAPMMeta?.path ?? "/en/contest",
    meta: indexMKwREhkAPMMeta || {},
    alias: indexMKwREhkAPMMeta?.alias || [],
    redirect: indexMKwREhkAPMMeta?.redirect,
    component: () => import("/workdir/pages/contest/index.vue").then(m => m.default || m)
  },
  {
    name: indexNrjrPDWjinMeta?.name ?? "discounts___ru",
    path: indexNrjrPDWjinMeta?.path ?? "/discounts",
    meta: indexNrjrPDWjinMeta || {},
    alias: indexNrjrPDWjinMeta?.alias || [],
    redirect: indexNrjrPDWjinMeta?.redirect,
    component: () => import("/workdir/pages/discounts/index.vue").then(m => m.default || m)
  },
  {
    name: indexNrjrPDWjinMeta?.name ?? "discounts___en",
    path: indexNrjrPDWjinMeta?.path ?? "/en/discounts",
    meta: indexNrjrPDWjinMeta || {},
    alias: indexNrjrPDWjinMeta?.alias || [],
    redirect: indexNrjrPDWjinMeta?.redirect,
    component: () => import("/workdir/pages/discounts/index.vue").then(m => m.default || m)
  },
  {
    name: indexYqXC3W8KKRMeta?.name ?? "faq___ru",
    path: indexYqXC3W8KKRMeta?.path ?? "/faq",
    meta: indexYqXC3W8KKRMeta || {},
    alias: indexYqXC3W8KKRMeta?.alias || [],
    redirect: indexYqXC3W8KKRMeta?.redirect,
    component: () => import("/workdir/pages/faq/index.vue").then(m => m.default || m)
  },
  {
    name: indexYqXC3W8KKRMeta?.name ?? "faq___en",
    path: indexYqXC3W8KKRMeta?.path ?? "/en/faq",
    meta: indexYqXC3W8KKRMeta || {},
    alias: indexYqXC3W8KKRMeta?.alias || [],
    redirect: indexYqXC3W8KKRMeta?.redirect,
    component: () => import("/workdir/pages/faq/index.vue").then(m => m.default || m)
  },
  {
    name: indexJ1sPgYGdnvMeta?.name ?? "index___ru",
    path: indexJ1sPgYGdnvMeta?.path ?? "/",
    meta: indexJ1sPgYGdnvMeta || {},
    alias: indexJ1sPgYGdnvMeta?.alias || [],
    redirect: indexJ1sPgYGdnvMeta?.redirect,
    component: () => import("/workdir/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexJ1sPgYGdnvMeta?.name ?? "index___en",
    path: indexJ1sPgYGdnvMeta?.path ?? "/en",
    meta: indexJ1sPgYGdnvMeta || {},
    alias: indexJ1sPgYGdnvMeta?.alias || [],
    redirect: indexJ1sPgYGdnvMeta?.redirect,
    component: () => import("/workdir/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexttwSwVVJLoMeta?.name ?? "partners___ru",
    path: indexttwSwVVJLoMeta?.path ?? "/partners",
    meta: indexttwSwVVJLoMeta || {},
    alias: indexttwSwVVJLoMeta?.alias || [],
    redirect: indexttwSwVVJLoMeta?.redirect,
    component: () => import("/workdir/pages/partners/index.vue").then(m => m.default || m)
  },
  {
    name: indexttwSwVVJLoMeta?.name ?? "partners___en",
    path: indexttwSwVVJLoMeta?.path ?? "/en/partners",
    meta: indexttwSwVVJLoMeta || {},
    alias: indexttwSwVVJLoMeta?.alias || [],
    redirect: indexttwSwVVJLoMeta?.redirect,
    component: () => import("/workdir/pages/partners/index.vue").then(m => m.default || m)
  },
  {
    name: indexk3qcO1cw9bMeta?.name ?? "rules___ru",
    path: indexk3qcO1cw9bMeta?.path ?? "/rules",
    meta: indexk3qcO1cw9bMeta || {},
    alias: indexk3qcO1cw9bMeta?.alias || [],
    redirect: indexk3qcO1cw9bMeta?.redirect,
    component: () => import("/workdir/pages/rules/index.vue").then(m => m.default || m)
  },
  {
    name: indexk3qcO1cw9bMeta?.name ?? "rules___en",
    path: indexk3qcO1cw9bMeta?.path ?? "/en/rules",
    meta: indexk3qcO1cw9bMeta || {},
    alias: indexk3qcO1cw9bMeta?.alias || [],
    redirect: indexk3qcO1cw9bMeta?.redirect,
    component: () => import("/workdir/pages/rules/index.vue").then(m => m.default || m)
  },
  {
    name: _91uuid_93FBaU04Y9qoMeta?.name ?? "verify-uuid___ru",
    path: _91uuid_93FBaU04Y9qoMeta?.path ?? "/verify/:uuid()",
    meta: _91uuid_93FBaU04Y9qoMeta || {},
    alias: _91uuid_93FBaU04Y9qoMeta?.alias || [],
    redirect: _91uuid_93FBaU04Y9qoMeta?.redirect,
    component: () => import("/workdir/pages/verify/[uuid].vue").then(m => m.default || m)
  },
  {
    name: _91uuid_93FBaU04Y9qoMeta?.name ?? "verify-uuid___en",
    path: _91uuid_93FBaU04Y9qoMeta?.path ?? "/en/verify/:uuid()",
    meta: _91uuid_93FBaU04Y9qoMeta || {},
    alias: _91uuid_93FBaU04Y9qoMeta?.alias || [],
    redirect: _91uuid_93FBaU04Y9qoMeta?.redirect,
    component: () => import("/workdir/pages/verify/[uuid].vue").then(m => m.default || m)
  },
  {
    name: component_45stubWRgRVU5zlHMeta?.name ?? undefined,
    path: component_45stubWRgRVU5zlHMeta?.path ?? "/sitemap.xml",
    meta: component_45stubWRgRVU5zlHMeta || {},
    alias: component_45stubWRgRVU5zlHMeta?.alias || [],
    redirect: component_45stubWRgRVU5zlHMeta?.redirect,
    component: component_45stubWRgRVU5zlH
  },
  {
    name: component_45stubWRgRVU5zlHMeta?.name ?? undefined,
    path: component_45stubWRgRVU5zlHMeta?.path ?? "/index-sitemap.xml",
    meta: component_45stubWRgRVU5zlHMeta || {},
    alias: component_45stubWRgRVU5zlHMeta?.alias || [],
    redirect: component_45stubWRgRVU5zlHMeta?.redirect,
    component: component_45stubWRgRVU5zlH
  },
  {
    name: component_45stubWRgRVU5zlHMeta?.name ?? undefined,
    path: component_45stubWRgRVU5zlHMeta?.path ?? "/ru-RU-sitemap.xml",
    meta: component_45stubWRgRVU5zlHMeta || {},
    alias: component_45stubWRgRVU5zlHMeta?.alias || [],
    redirect: component_45stubWRgRVU5zlHMeta?.redirect,
    component: component_45stubWRgRVU5zlH
  },
  {
    name: component_45stubWRgRVU5zlHMeta?.name ?? undefined,
    path: component_45stubWRgRVU5zlHMeta?.path ?? "/en-US-sitemap.xml",
    meta: component_45stubWRgRVU5zlHMeta || {},
    alias: component_45stubWRgRVU5zlHMeta?.alias || [],
    redirect: component_45stubWRgRVU5zlHMeta?.redirect,
    component: component_45stubWRgRVU5zlH
  }
]