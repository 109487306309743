import type { Raw, Component } from "vue";
import { useEventBus } from "@vueuse/core";
import { openKey } from "~/types/eventBusKey";

export default defineNuxtPlugin(() => {
  const openModal = useEventBus(openKey);
  const closeModal = useEventBus("close");

  const modal = {
    open(comp: Raw<Component>, data: object = {}): void {
      openModal.emit({ comp, data });
    },
    close: () => closeModal.emit,
  };

  return {
    provide: {
      modal,
    },
  };
});
