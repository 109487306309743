<template>
  <div>
    <NuxtLayout>
      <NuxtLoadingIndicator color="#035bd2" :throttle="200" />
      <!--    <NuxtPage :transition="transition" />-->
      <NuxtPage />
      <TheModal />
      <TheNotifications />
    </NuxtLayout>
  </div>
</template>

<script setup lang="ts">
import type { Script } from "@unhead/schema";
import TheModal from "~/components/default/TheModal.vue";
import { pagesApi } from "~/utils/api/pages";
// import { useClaimsStore } from "~/stores/useClaimsStore";

// const { finalizePendingLocaleChange } = useI18n();

// const onBeforeEnter = async () => {
//   await finalizePendingLocaleChange();
// };
const transition = {
  name: "page",
  mode: "out-in",
  onBeforeEnter: async () => {
    const { finalizePendingLocaleChange, locale } = useNuxtApp().$i18n;
    await finalizePendingLocaleChange();
  },
};

// onMounted(() => {
//   useClaimsStore().loadClaims();
// });

// const jivoSrc = computed(() => {
//   if (locale.value === "ru") {
//     return "//code.jivo.ru/widget/VBWbaIDoHL";
//   }
//   return "//code.jivo.ru/widget/nhPznL8a05";
// });
const { data: chatData, refresh } = await useAsyncData(() =>
  pagesApi.fetchChat(),
);

const { locale } = useI18n();

watch(
  () => locale.value,
  () => {
    refresh();
  },
);

const scripts = computed(() => {
  const scripts = [];
  if (chatData.value) {
    scripts.push(chatData.value as Script);
    return scripts;
  }
  return [];
});
useHead({
  script: scripts,
});
</script>
