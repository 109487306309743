import { appendResponseHeader, H3Event } from "h3";

export const fetchWithCookie = async (
  event: H3Event,
  url: string,
  opts: any = {},
) => {
  const res = await $fetch.raw(url, opts);
  const cookies = (res.headers.get("set-cookie") || "").split(",");
  for (const cookie of cookies) {
    appendResponseHeader(event, "set-cookie", cookie);
  }
  return res._data;
};
