import useDirectionsCalculator from "~/composables/calculator/useDirectionsCalculator";

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.hook("i18n:localeSwitched", async () => {
    await useDirectionsCalculator().onCurrencyChange();
  });

  nuxtApp.hook("i18n:beforeLocaleSwitch", () => {
    if (window.jivo_api) window.jivo_destroy();
  });
});
