<template>
  <div>
    <NuxtLayout>
      <div :class="$style.ErrorPage" class="container">
        <section :class="$style.section">
          <h1 :class="$style.code">{{ error.statusCode }}</h1>
          <p :class="$style.description">
            {{ description }}
            <VLink
              v-if="statusCode === 404"
              variant="secondary"
              @click="handleError"
            >
              {{ t("главной страницы") }}
            </VLink>
          </p>
          
        </section>
      </div>
    </NuxtLayout>
  </div>
</template>

<script setup lang="ts">
import type { NuxtError } from "#app";
import type { PropType } from "vue";

const props = defineProps({
  error: {
    type: Object as PropType<NuxtError>,
    required: true,
  },
});

const { t } = useI18n({
  useScope: "local",
});

const statusCode = computed(() => props.error.statusCode);

const description = computed(() => {
  if (statusCode.value === 404) {
    return t("Сожалеем");
  }
  return t("Ошибка");
});
const localePath = useLocalePath();

const handleError = () => clearError({ redirect: localePath("/") });
</script>

<i18n lang="json">
{
  "ru": {
    "Сожалеем": "Сожалеем, но такой страницы не существует. Пожалуйста, начните с",
    "главной страницы": "главной страницы",
    "Ошибка": "Ошибка, пожалуйста, попробуйте позже"
  },
  "en": {
    "Сожалеем": "We are sorry, but there is no such page. Please start from the",
    "главной страницы": "home page",
    "Ошибка": "Error, please try again later"
  }
}
</i18n>

<style lang="scss" module>
.ErrorPage {
  padding-bottom: 8rem;
}

.section {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
}

.code {
  font-size: 20rem;
  font-weight: 450;
  line-height: 90%;
  letter-spacing: -4px;
  background-image: $gradient-blue;
  color: transparent;
  background-clip: text;

  @include respond-to(xs) {
    font-size: 140px;
    letter-spacing: -2.8px;
  }
}

.description {
  max-width: 46rem;
  width: 100%;
  text-align: center;
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 130%;
  color: var(--text-color-8);

  @include respond-to(xs) {
    font-size: 16px;
  }

  a {
    color: $brand-blue;
  }
}
</style>
