export function getBaseUrl() {
  const { $i18n } = useNuxtApp();
  const config = useRuntimeConfig();
  const { locale, finalizePendingLocaleChange } = $i18n;
  // console.log("locale.value", locale.value);
  //
  // console.log("locale.value", locale.value);
  // finalizePendingLocaleChange();
  return locale.value === "ru"
    ? config.public.baseUrl
    : `${config.public.baseUrl}/en`;
}
