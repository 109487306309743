import { apiFetch, useApiFetch } from "~/utils/fetch/fetchApi";
import type { Contest, Review } from "~/types/pages";

const pagesApiUrl = {
  fetchContestUrl: "/api/v1/pages/contest/",
  fetchReviewsUrl: "/api/v1/pages/reviews/",
  fetchDiscountsUrl: "/api/v1/bonus/",
  fetchChatUrl: "/api/v1/calculator/chat/",
};

export const pagesApi = {
  fetchContest,
  fetchReviews,
  fetchDiscounts,
  fetchChat,
};

interface FetchReviewsResponse {
  summaryCount: number;
  reviews: Review[];
}

interface Discounts {
  fromUsd: number;
  toUsd: number;
  percent: number;
}

interface FetchDiscountsResponse {
  fork: Discounts[];
}

function fetchReviews() {
  return useApiFetch<FetchReviewsResponse>(pagesApiUrl.fetchReviewsUrl, {
    server: false,
    lazy: true,
  });
}

function fetchContest() {
  return useApiFetch<Contest>(pagesApiUrl.fetchContestUrl);
}

function fetchDiscounts() {
  return useApiFetch<FetchDiscountsResponse>(pagesApiUrl.fetchDiscountsUrl);
}

interface Chat {
  src: string;
  innerHTML: string;
  async: boolean;
}

function fetchChat() {
  return apiFetch<Chat>(pagesApiUrl.fetchChatUrl);
}
