import { calculatorApi } from "~/utils/api/calculator";

export default function useDirectionsCalculator() {
  const store = useCalculatorStore();
  const onFromCurrencyChange = async (id: number) => {
    store.setFromCurrencyId(id);
    const toCurrenciesIds = await calculatorApi.getToCurrencies({ from: id });
    if (Array.isArray(toCurrenciesIds)) {
      store.setToCurrencies(toCurrenciesIds);
    }
    if (!store.toCurrencyId) return;
    if (!toCurrenciesIds.includes(store.toCurrencyId)) {
      store.setToCurrencyId(toCurrenciesIds[0]);
    }
    await onCurrencyChange();
  };

  const onToCurrencyChange = async (id: number) => {
    store.setToCurrencyId(id);
    await onCurrencyChange();
  };

  const onCurrenciesChange = async (from: number, to: number) => {
    store.setFromCurrencyId(from);
    store.setToCurrencyId(to);
    const toCurrenciesIds = await calculatorApi.getToCurrencies({ from });
    if (Array.isArray(toCurrenciesIds)) {
      store.setToCurrencies(toCurrenciesIds);
    }
    await onCurrencyChange();
  };

  const onCurrencyChange = async () => {
    const from = store.fromCurrencyId;
    const to = store.toCurrencyId;
    if (!from || !to) {
      return;
    }
    const pairData = await calculatorApi.getPairData({ from, to });
    store.setPairData(pairData);
    store.updateData();
  };

  return {
    onFromCurrencyChange,
    onToCurrencyChange,
    onCurrenciesChange,
    onCurrencyChange,
  };
}
