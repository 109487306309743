<template>
  <NuxtLink :class="[$style.VLink, mods]" :disabled="disabled">
    <slot />
  </NuxtLink>
</template>

<script setup lang="ts">
type ButtonVariant = "primary" | "secondary";
type ButtonColor = "blue";

interface LinkProps {
  variant?: ButtonVariant;
  color?: ButtonColor;
  disabled?: boolean;
}

const props = withDefaults(defineProps<LinkProps>(), {
  variant: "primary",
  color: "blue",
  disabled: false,
});

const style = useCssModule();

const mods = computed(() => ({
  [style[props.variant]]: true,
  [style[props.color]]: true,
  [style.disabled]: props.disabled,
}));
</script>

<style module lang="scss">
.VLink {
  display: inline-flex;
  align-items: center;
  font-family: $base-font;
  transition: all $base-transition;
  cursor: pointer;
  user-select: none;
}

.primary {
  position: relative;
  font-size: 1.8rem;
  font-weight: 450;
  line-height: 130%;

  &:after {
    position: absolute;
    content: "";
    width: 100%;
    height: 1px;
    left: 0;
    bottom: -6px;
  }
}

.secondary {
  position: relative;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 130%;
}

.blue {
  position: relative;
  color: var(--link-color);

  &:after {
    background-color: var(--link-color);
  }

  &.disabled {
    color: $base-150;

    &:after {
      background-color: $base-150;
    }
  }
}

.disabled {
  pointer-events: none;
}
</style>
