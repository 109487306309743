<template>
  <transition appear name="view">
    <div
      :class="$style.NotificationItem"
      @mouseover="onMouseover"
      @mouseleave="onMouseleave"
    >
      <div :class="$style.wrapper">
        <div :class="$style.content">
          <div v-if="icon" :class="$style.icon">
            <SvgoIconSuccess v-if="icon === 'success'" class="icon-close" />
            <SvgoIconFail v-else-if="icon === 'fail'" class="icon-close" />
          </div>
          <div :class="$style.main">
            <p>
              <slot name="title" :title="title">
                {{ title }}
              </slot>
            </p>
          </div>
          <button :class="$style.icon" @click="$emit('close')">
            <SvgoIconClose class="icon-close" />
          </button>
        </div>
        <div v-if="timeout" :class="$style.progress" :style="progressStyle" />
      </div>
    </div>
  </transition>
</template>

<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    id: string | number;
    title: string;
    timeout?: number;
    icon?: string;
  }>(),
  {
    id: "",
    title: "",
    timeout: 3000,
    icon: "",
  },
);

const emit = defineEmits(["close"]);

let timer: any = null;
const remaining = ref(props.timeout);

const progressStyle = computed(() => {
  const remainingPercent = (remaining.value / props.timeout) * 100;

  return { width: `${remainingPercent || 0}%` };
});

function onMouseover() {
  if (timer) {
    timer.pause();
  }
}

function onMouseleave() {
  if (timer) {
    timer.resume();
  }
}

function onClose() {
  if (timer) {
    timer.stop();
  }
  emit("close");
}

onMounted(() => {
  if (!props.timeout) {
    return;
  }

  timer = useTimer(() => {
    onClose();
  }, props.timeout);

  watchEffect(() => {
    remaining.value = timer.remaining.value;
  });
});

onUnmounted(() => {
  if (timer) {
    timer.stop();
  }
});
</script>

<style lang="scss" module>
.NotificationItem {
  width: 100%;
  pointer-events: auto;
  background-color: var(--main-block-bg);
  border-radius: $border-radius-xs;
  border: 1px solid var(--border-color);
}

.wrapper {
  position: relative;
  overflow: hidden;
  border-radius: $border-radius-xs;
}

.content {
  display: flex;
  align-items: center;
  padding: 1.6rem;
  gap: 1.6rem;
}

.main {
  flex: 1 1 0;
  width: 0;
}

.progress {
  position: absolute;
  bottom: 0;
  height: 4px;
  background-color: $brand-blue;
}

.icon {
  display: flex;
}
</style>
