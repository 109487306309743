import { useUserStore } from "~/stores/useUserStore";
import { authApi } from "~/utils/api/auth";

export default defineNuxtPlugin(async (nuxtApp) => {
  if (nuxtApp.payload.error) {
    return {};
  }
  const { cookie } = useRequestHeaders();
  if (!cookie || !cookie.includes("refreshToken")) {
    return {};
  }
  const { data, error } = await authApi.refreshToken();

  if (error.value) {
    const headers = useRequestHeaders(["cookie"]);
    const event = useRequestEvent();
    await authApi.logout(event, headers);
  }
  if (data.value) {
    useUserStore().setToken(data.value);
    await useUserStore().loadUser();
  }
  return {};
});
