import { StorageSerializers } from "@vueuse/core";
import { useApiFetch } from "~/utils/fetch/fetchApi";
import { getBaseUrl } from "~/utils/baseUrl";

export default async <T>(url: string) => {
  const baseUrl = getBaseUrl();
  const cached = useSessionStorage<T>(baseUrl + url, null, {
    serializer: StorageSerializers.object,
  });

  if (!cached.value) {
    const { data, error } = await useApiFetch<T>(url);

    if (error.value) {
      throw createError({
        statusCode: 500,
        statusMessage: `Could not fetch data from ${url}`,
        fatal: true,
      });
    }

    cached.value = data.value as T;
  }

  return cached;
};
