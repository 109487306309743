import { calculatorApi } from "~/utils/api/calculator";

export function useSettings() {
  const settings = useState<any>("settings", () => null);

  const getSettings = async () => {
    const { data, error } = await calculatorApi.fetchSettings();
    if (error.value) {
      return;
    }
    settings.value = data.value;
  };

  const contestStatus = computed(() => settings.value?.contest);

  return {
    contestStatus,
    getSettings,
  };
}
