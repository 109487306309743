<template>
  <Teleport to="body">
    <div :class="$style.TheNotifications">
      <div v-if="notifications.length" :class="$style.container">
        <div v-for="notification of notifications" :key="notification.id">
          <VNotification
            v-bind="notification"
            @close="toast.remove(notification.id)"
          />
        </div>
      </div>
    </div>
  </Teleport>
</template>

<script setup lang="ts">
import type { ToastNotification } from "~/types/notification";

const toast = useToast();

const notifications = useState<ToastNotification[]>(
  "toastNotifications",
  () => [],
);
</script>

<style lang="scss" module>
.TheNotifications {
  z-index: 99;
  position: fixed;
  bottom: 0;
  display: flex;
  justify-content: flex-end;
  width: 33.6rem;
}

.container {
  width: 100%;
  padding: 2.4rem;
  overflow-y: auto;

  > :not(:first-child) {
    margin-top: 1rem;
  }
}
</style>
